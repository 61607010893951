<template>
<div id="app">
    <transition name="fade">
        <div v-if="loaded">
                <div class="navbar_container" :class="{ stickNavbar: scrolledALittleFromTop }">
                    <div class="mycustomnav_container">
                    <div class="mynavbarandlogo" :class="{ stickNavbadandlogo: scrolledALittleFromTop }">
                        <div class="logo_items">
                            <img v-if="scrolledALittleFromTop" alt="Vue logo" src="./assets/logoblue.png" class="pt-4 pr-5 pl-4 pb-3">
                            <img v-else alt="Vue logo" src="./assets/logo.png" class="pt-4 pr-5 pl-4 pb-3">
                        </div>
                        <div class="menu_items" :class="{ stickMenuitems: scrolledALittleFromTop }">
                            <div class="links"><a href="#quisommesnous">QUI SOMMES-NOUS?</a></div>
                            <div class="links"><a href="#notremission">NOTRE MISSION</a></div>
                            <div class="links"><a href="#inscriptionseton">INSCRIPTION</a></div>
                            <div class="links"><a href="#nosoffres">NOS OFFRES</a></div>
                            <div class="links"><a href="#telechargement">TÉLÉCHARGEMENT</a></div>
                            <div class="links"><a href="#contactsection">CONTACT</a></div>
                        </div>
                        <div class="togglebtn_items pt-5 pr-4" v-if="!mobilemenu">
                            <b-icon v-if="scrolledALittleFromTop"  class="mytogglebtn"  style="width: 27px; height: 27px;" stroke="#005b94" icon="list" @click="openmobilemenu()"></b-icon>
                            <b-icon v-else  class="mytogglebtn"  style="width: 27px; height: 27px;" stroke="white" icon="list" @click="openmobilemenu()"></b-icon>
                        </div>
                        <div v-else  class="togglebtn_items pt-5 pr-4">
                            <b-icon v-if="scrolledALittleFromTop" class="mytogglebtn"  icon="x-lg" style="width: 27px; height: 27px; color:#005b94;" @click="closemobilemenu()"></b-icon>
                            <b-icon v-else class="mytogglebtn"  icon="x-lg" style="width: 27px; height: 27px; color:#ffffff;" @click="closemobilemenu()"></b-icon>
                        </div>
                    </div>
                    <div v-if="mobilemenu" class="mobilemenu_items pt-5 pb-5">
                        <div class="linksmobilemenu pb-3"><a href="#quisommesnous">QUI SOMMES-NOUS?</a></div>
                        <div class="linksmobilemenu pb-3"><a href="#notremission">NOTRE MISSION</a></div>
                        <div class="linksmobilemenu pb-3"><a href="#inscriptionseton">INSCRIPTION</a></div>
                        <div class="linksmobilemenu pb-3"><a href="#nosoffres">NOS OFFRES</a></div>
                        <div class="linksmobilemenu pb-3"><a href="#telechargement">TÉLÉCHARGEMENT</a></div>
                        <div class="linksmobilemenu"><a href="#contactsection">CONTACT</a></div>
                    </div>

                    </div>
                </div>
            <div class="topblue_container">
                <b-container class="accroche pt-4">
                    <b-row>
                        <b-col>
                            <h1><b>Gagnez du temps !</b></h1>
                            <div class="txt2acc pb-4">
                                Centralisez toutes vos ressources avec CréaSavant. Notre plateforme vous fournit des conseils experts, des méthodes efficaces et du contenu ciblé pour optimiser votre préparation sans perdre de temps.
                            </div>
                            <b-button variant="danger" pill class="mr-2" @click="sinscrire()">S'inscrire</b-button>
                            <b-button variant="success" pill @click="decouvrir()">Découvrir</b-button>
                        </b-col>
                    </b-row>
                </b-container>
                <b-container>
                    <b-row>
                        <b-col class="pt-5">
                            <img :src="wurl+'/imgsrv/'+pic01" alt="Image" class="imghome" />
                        </b-col>
                    </b-row>
                </b-container>

            </div>
            <div class="inscription_section" id="inscriptionseton">
                <b-container>
                    <b-row class="pb-5">
                        <b-col class="inscriptCol1">

                            <h2><b>Inscrivez-vous</b></h2>

                            Rejoignez CréaSavant en quelques étapes simples. <br>

                            Remplissez le formulaire et accédez à une préparation de qualité pour exceller dans vos épreuves.<br><br>

                            <b>Vos informations</b> sont essentielles pour confirmer votre inscription.<br>

                            <b>Nous garantissons</b> la confidentialité de vos données, qui ne seront jamais utilisées à des fins commerciales.<br>

                            <br>Un email de confirmation vous sera envoyé sous 24 heures.

                        </b-col>
                        <b-col class="inscriptCol2">
                            <div class="col2container">
                                <b-form @submit="onSubmit">
                                    <b-form-input id="input-1" v-model="form.nom" placeholder="Nom complet" required></b-form-input>
                                    <b-form-input id="input-1" v-model="form.email" placeholder="Email adresse" type="email" required class="mt-3"></b-form-input>
                                    <b-form-input id="input-1" v-model="form.tel" placeholder="Téléphone" required class="mt-3"></b-form-input>
                                </b-form>
                            </div>
                            <div class="col2container ">
                                <div class="pt-3 ">
                                    <b-form-checkbox id="checkbox-1" v-model="status" name="checkbox-1" value="accepted" unchecked-value="not_accepted">
                                        <span class="condition">J'ai lu et j'accepte la Politique de confidentialité et les Conditions générales d'utilisation.</span>
                                    </b-form-checkbox>
                                </div>
                            </div>
                            <div class="col2container ">
                                <div class="pt-3">
                                    <b-button variant="info" pill id="btninscription">S'inscrire</b-button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
            <div class="quisommesnous_section pt-5" id="quisommesnous">
                <b-container>
                    <b-row>
                        <b-col class="pt-5 pb-4">
                            <h2><b>Qui sommes-nous ?</b></h2>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            CréaSavant se distingue comme la référence incontournable pour la préparation aux baccalauréats français. Nos professeurs renommés, véritables experts, vous accompagnent vers la réussite et transforment vos aspirations en succès éclatant.
                        </b-col>
                    </b-row>
                </b-container>

            </div>
            <div class="creasavant_section pt-5" id="notremission">
                <b-container>
                    <b-row>
                        <b-col class="pt-5 pb-4">
                            <h2><b>En quoi CréaSavant est-il le choix idéal pour vous ?</b></h2>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="4" md="4" sm="4" xs="12" class="pt-4">
                            <h3><b>Développez votre confiance !</b></h3><br>
                            Bénéficiez d'un accompagnement personnalisé qui vous aide à construire une solide confiance en vous et à gérer le stress, vous préparant ainsi à affronter vos épreuves avec assurance.
                        </b-col>
                        <b-col lg="4" md="4" sm="4" xs="12" class="pt-4">
                            <h3><b>Adoptez des méthodes éprouvées !</b></h3><br>
                            Accédez à des techniques de préparation testées et efficaces, conçues pour vous aider à maîtriser chaque matière et à optimiser vos résultats.

                        </b-col>
                        <b-col lg="4" md="4" sm="4" xs="12" class="pt-4">
                            <h3><b>Profitez de l’expertise à prix raisonnable !</b></h3><br>
                            Avec des frais accessibles, bénéficiez des conseils et de l’accompagnement de professeurs experts dans le système éducatif français, garantissant une préparation de haute qualité.

                        </b-col>
                    </b-row>
                </b-container>
                <b-container id="nosoffres" class="pt-5">
                    <b-row>
                        <b-col class="pt-5 pb-4">
                            <h2><b>Découvrez Notre Exclusive Package</b></h2>
                        </b-col>
                    </b-row>
                    <div class="containPack">
                        <div class="pack">
                            <div>
                                <b-icon icon="capslock-fill" class="iconpack"></b-icon>
                            </div>
                            <div class="pt-3">
                                <span class="titlepack"><b>Pack Excellence (Terminale)</b></span>
                                <div class="textPack">Toutes matières : Épreuves finales + Contrôle continu
                                    *Coaching oraux et sports inclus</div>
                            </div>
                        </div>
                        <div class="pack">
                            <div>
                                <b-icon icon="bag-fill" class="iconpack"></b-icon>
                            </div>
                            <div class="pt-3">
                                <span class="titlepack"><b>Pack Performance (1re)</b></span>
                                <div class="textPack">Toutes matières + Contrôle continu + EAF
                                    *Coaching oraux et sports inclus
                                </div>
                            </div>
                        </div>
                        <div class="pack">
                            <div>
                                <b-icon icon="award-fill" class="iconpack"></b-icon>
                            </div>
                            <div class="pt-3">
                                <span class="titlepack"><b>Pack Spécialité Plus (1re& Terminale)</b></span>
                                <div class="textPack">2 matières de spécialité + Bonus : 1 matière d'enseignement obligatoire au choix</div>
                            </div>
                        </div>
                        <div class="pack">
                            <div>
                                <b-icon icon="person-fill" class="iconpack"></b-icon>
                            </div>
                            <div class="pt-3">
                                <span class="titlepack"><b>Pack EAF (1re)</b></span>
                                <div class="textPack">Épreuves anticipées de Français oral
                                    Épreuves anticipées de Français écrit
                                </div>
                            </div>
                        </div>
                        <div class="pack">
                            <div>
                                <b-icon icon="headset" class="iconpack"></b-icon>
                            </div>
                            <div class="pt-3">
                                <span class="titlepack"><b>Pack Eloquence</b></span>
                                <div class="textPack">Grand Oral
                                    Étude de gestion (STMG)
                                    Oral LVA-LVB-EMC
                                </div>
                            </div>
                        </div>
                        <div class="pack">

                            <img :src="wurl+'/imgsrv/pack/danceping.svg'" alt="Image" width="175" class="iconpack" />
                            <div class="pt-3">
                                <span class="titlepack"><b>Pack Sport</b></span>
                                <div class="textPack">Ping-pong<br>
                                    Danse
                                </div>
                            </div>
                        </div>
                    </div>
                </b-container>
                <b-container>
                    <b-row>
                        <b-col class="pt-5 pb-4">
                            <h2><b>CréaSavant en chiffre</b></h2>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="3" md="3" sm="3" xs="12" class="pt-4">
                            <span class="chiffreCrea"><b>100 %</b></span>
                            <h3><b>Bac STMG 2024</b></h3><br>
                        </b-col>
                        <b-col lg="3" md="3" sm="3" xs="12" class="pt-4">
                            <span class="chiffreCrea"><b>100 %</b></span>
                            <h3><b>Bac Général 2024 </b></h3><br>

                        </b-col>
                        <b-col lg="3" md="3" sm="3" xs="12" class="pt-4">
                            <span class="chiffreCrea"><b>17.25</b></span>
                            <h3><b>Grand Oral</b></h3><br>

                        </b-col>
                        <b-col lg="3" md="3" sm="3" xs="12" class="pt-4">
                            <span class="chiffreCrea2"><b>Pionniers à Madagascar</b></span>
                            <h3><b>Cours de Python pour les ados</b></h3><br>

                        </b-col>
                    </b-row>
                </b-container>

            </div>
            <div class="creasavant_section pt-5" id="telechargement">
                <b-container>
                <b-row>
                    <b-col>
                        <h2><b>Téléchargement</b></h2>
                    </b-col>
                </b-row>
                </b-container>
                <b-container>
                <b-row>
                    <b-col lg="4" md="4" sm="4" xs="12" class="pt-4">
                        Dossier Inscription<br>
                        <a :href="wurl+'/doc/inscription.pdf'"><b-icon icon="file-earmark-arrow-down" class="socialicon"></b-icon></a>
                        </b-col>
                    <b-col lg="4" md="4" sm="4" xs="12" class="pt-4">
                        Fourniture Première<br>
                        <a :href="wurl+'/doc/fournit_premiere.pdf'"><b-icon icon="file-earmark-arrow-down" class="socialicon"></b-icon></a>
                        </b-col>
                    <b-col lg="4" md="4" sm="4" xs="12" class="pt-4">
                        Fourniture Terminale<br>
                        <a :href="wurl+'/doc/fournit_terminale.pdf'"><b-icon icon="file-earmark-arrow-down" class="socialicon"></b-icon></a>
                        </b-col>
                    <b-col lg="4" md="4" sm="4" xs="12" class="pt-4">
                        Emploi du temps<br>
                        <a :href="wurl+'/doc/edt-24-25.pdf'"><b-icon icon="file-earmark-arrow-down" class="socialicon"></b-icon></a>
                        </b-col>
                </b-row>
                </b-container>
            </div>
            <div class="temoignage_section pt-4">
                <b-container>
                    <b-row>
                        <b-col class="pt-5 pb-4">
                            <h4><b>Découvrez les témoignages exclusifs de nos bacheliers 2024, véritables ambassadeurs de CréaSavant.</b></h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6" md="6" sm="6" xs="12" v-for="(temoin, index) in temoignageData" :data-index="index" :key=temoin.id_temoin>
                            <b-card no-body class="overflow-hidden" :key="temoin.id_temoignage" :id="'card-'+index" style="max-width: 540px;">
                                <b-row>
                                    <b-col md="4">
                                        <b-card-img :src="wurl+'/imgsrv/temoignage/'+temoin.pic" alt="Image" class="tempic"></b-card-img><br>
                                        <span class="nameTem">{{temoin.nom}}</span><br>
                                        <span class="classeTem">{{temoin.classe}}</span><br>
                                    </b-col>
                                    <b-col md="8">
                                        <b-card-body>
                                            <b-card-text>
                                                "{{temoin.content}}"
                                            </b-card-text><br>
                                        </b-card-body>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
            <div class="social_section pt-4 pb-4">
                <div class="sociallogocontainer">
                    <b-icon icon="facebook" class="socialicon"></b-icon>
                    <b-icon icon="instagram" class="socialicon"></b-icon>
                    <b-icon icon="whatsapp" class="socialicon"></b-icon>
                    <img :src="wurl+'/imgsrv/social/tiktok.svg'" alt="Image" class="socialsvg" />

                </div>
            </div>
            <div class="contact_section pb-4" id="contactsection">
                <b-container>
                    <b-row>
                        <b-col class="pt-5">
                            <h2><b>Contact</b></h2>
                            For registration questions please get in touch using the contact details below. For any questions use the form.
                            <h2 class="pt-4"><b>Adresse</b></h2>
                            <b-icon icon="geo-alt-fill" aria-hidden="true"></b-icon> Besarety à 5mn de La Rotonde<br>
                            <b-icon icon="phone" aria-hidden="true"></b-icon> +261 347441067 +261 32 92 242 79<br>
                            <b-icon icon="envelope-fill" aria-hidden="true"></b-icon> contact@creasavant.com<br>
                        </b-col>
                        <b-col class="pb-5 pt-5">
                            <div class="col2container">
                                <b-form @submit="onSubmit">
                                    <b-form-input id="input-1" v-model="form.nom" placeholder="Nom complet" required></b-form-input>
                                    <b-form-input id="input-1" v-model="form.email" placeholder="Adresse email" type="email" required class="mt-3"></b-form-input>
                                     <b-form-textarea
      id="textarea"
      v-model="text"
      placeholder="Votre message..."
      rows="3"
      max-rows="6"
      class="mt-3"
    ></b-form-textarea>
                                </b-form>
                                <div class="pt-3">
                                    <b-button variant="info" pill id="btninscription">Envoyer</b-button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>

            </div>
            <div class="footer_section">
                <b-container>
                    <b-row>
                        <b-col lg="4" md="4" sm="4" xs="12" class="pt-4">
                            <h4><b>A propos de CréaSavant</b></h4>
                            Rejoignez CréaSavant en quelques étapes simples. <br>
                        </b-col>
                        <b-col lg="4" md="4" sm="4" xs="12" class="pt-3">
                            <div class="linkFooter pt-4">
                                <div class="linkfootr"><a href="#quisommesnous">Qui sommes-nous ?</a></div>
                                <div class="linkfootr"><a href="#notremission">Notre mission</a></div>
                                <div class="linkfootr"><a href="#inscriptionseton">Inscription</a></div>
                                <div class="linkfootr"><a href="#nosoffres">Nos Offres</a></div>
                                <div class="linkfootr"><a href="#telechargement">Téléchargement</a></div>
                                <div class="linkfootr"><a href="#contactsection">Contact</a></div>
                            </div>
                            
                        </b-col>
                        <b-col lg="4" md="4" sm="4" xs="12" class="pt-4">
                            <div class="sociallogocontainer">
                                <b-icon icon="facebook" class="socialiconfooter"></b-icon>
                                <b-icon icon="instagram" class="socialiconfooter"></b-icon>
                                <b-icon icon="whatsapp" class="socialiconfooter"></b-icon>
                                <img :src="wurl+'/imgsrv/social/tiktokwhite.svg'" alt="Image" class="socialiconfootersvg" />

                            </div>

                        </b-col>

                    </b-row>
                </b-container>
                <b-container>
                    <b-row>
                        <b-col class="footercopyright pt-4 pb-2">
                            Copyright © 2024 CréaSavant - Powered by Graphikodesign
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </div>
    </transition>
</div>
</template>

<script>
import {
    globalSiteSetting
} from "./main.js";

import axios from "axios";

export default {
    data() {
        return {
            wurl: globalSiteSetting.siteurl,
            isdarkmode: false,
            expanded: false,
            pic01: 'creapic02.jpg',
            status: 'not_accepted',
            form: {
                nom: ''
            },
            mobilemenu: false,
            temoignageData: [],
            loaded: false,
            scrolledALittleFromTop: false,
            text:''


        }
    },
    methods: {
        closemobilemenu() {
            this.mobilemenu = false
        },
        openmobilemenu() {
            this.mobilemenu = true
        },
        onSubmit() {
            console.log('yes submit')
        },
        retrievedatafortemoignage() {
            var weburl = this.wurl;
            axios
                .get(weburl + "/api.data.php?req=dynamicdata&p=temoignage&auth=razafy", {
                    crossDomain: true,
                })
                .then((response) => {
                    //console.log('the response are:' + response.data[0].slide_file)
                    this.temoignageData = this.temoignageData.concat(response.data.all);
                    this.loaded = true
                });
        },
        onScroll() {
            var scrollValue = scrollY
            if (scrollValue > 74) { 
                console.log('scroll value exceed 75')
                this.scrolledALittleFromTop = true

            } else {
                this.scrolledALittleFromTop = false
                console.log('window scrolled:' + scrollValue)
            }
        },
        sinscrire () {
            window.location.href = "#inscriptionseton"
        },
        decouvrir () {
            window.location.href = "#nosoffres"
        }

    },
    created() {
         window.addEventListener('scroll', this.onScroll);
    },
    mounted() {
        this.retrievedatafortemoignage()

      
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #000a13;

}

html { scroll-behavior: smooth; }

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1.5s;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

.topblue_container {
    background-color: #005b94;
    width: 100vw;
    height: 880px;
}

.linkFooter {
    display: flex;
    flex-direction: column;
}

.classeTem {
    font-size: 8pt;
}
/* my custom menu */

.mynavbarandlogo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.containPack {
    display: flex;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly
}

.pack {
    display: flex;
    width: 280px;
    height: 280px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.titlepack {
    font: 400 1.4rem/1.6rem "Montserrat", sans-serif;
    font-size: 13pt;
    line-height: .5;
}

.iconpack {
    color: #fedd0d;
    font-size: 48pt;
}

.textPack {
    font-weight: 200;
    font-size: 12pt;
    line-height: 14pt;
}

.logo_items {}

.tempic {
    max-width: 100px !important;
}

.card-body {
    padding: 0 !important;
}

.form-control {
    background-color: #f4f0d2 !important;
    border: 1px solid #fedd0d !important;
}

.menu_items {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    gap: 25px;
    padding-top: 90px;
}

.togglebtn_items {
    display: none;
}

.mytogglebtn {
    cursor: pointer;
}

.mobilemenu_items {
    display: none;
    background-color: #f7f7f7;

}

/* end of custom menu */
.inscriptCol1,
.inscriptCol2 {
    color: #01253c;
    padding-top: 70px;
    text-align: justify;
}

.col2container {
    width: 81%;
    margin: auto;
}

.imghome {
    border-radius: 36px;
    width: 100%;
    max-height: 600px;
}

.navbar {
    top: 6px;
    font-size: 13pt;
    font-weight: 700;
    color: white;
}

.mynav {
    float: right;
}

/* custom navbar */
.navbar-light .navbar-nav .nav-link {
    color: rgb(255, 255, 255) !important;
}

.navbar-light-color {
    color: white;
}

.navbar-light .navbar-toggler {
    color: rgba(255, 255, 255, 0.999) !important;
    border: none;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #fedd0d !important;
}

#mynavbartoggle {
    color: white;
}

#mytoggleexpandedicon {
    position: relative;
    right: -180px;
}

/* end custom navbar */
h1,
h2 {
    font: 700 2.75rem/3.375rem "Montserrat", sans-serif;
}

h3 {
    font: 700 2.75rem/3.375rem "Montserrat", sans-serif;
}

@keyframes reveal {
    from {
        font: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1.5);
        opacity: 1;
    }
}

.accroche {
    color: white;
}

.txt2acc {
    font: 400 1.125rem/1.75rem "Montserrat", sans-serif;
    width: 85%;
    margin: auto;
}

.btn-danger {
    color: #005b94 !important;
    background-color: #fddd0e !important;
    border-color: #fddd0e !important;
}

.btn-danger:hover {
    color: #fff !important;
    background-color: #005b94 !important;
    border-color: white !important;
    border-width: 2px;

}

.btn-success {
    color: #fff !important;
    background-color: #005b94 !important;
    border-color: white !important;
}

.btn-success:hover {
    color: #005b94 !important;
    background-color: white !important;
    border-color: white !important;
}

.inscription_section,
.contact_section {
    background-color: #fedd0d;
}

.quisommesnous_section,
.creasavant_section,
.temoignage_section,
.social_section {
    background-color: #f3f1e4;

}


.footer_section {
    background-color: #005b94;
    color: white;
}

.footercopyright {
    font-size: 9pt;
}
.condition {
    font-size: 11pt;
}

.btn-info {
    color: black !important;
    background-color: #fedd0d !important;
    border-color: black !important;
}

.btn-info:hover {
    color: black !important;
    background-color: white !important;
    border-color: white !important;
}

#btninscription {
    width: 100%;
}

.chiffreCrea,
.chiffreCrea2 {
    font: 700 2.75rem/3.375rem "Montserrat", sans-serif;
    color: #fddd0e;
    font-size: 41pt;
    font-weight: bolder;
    transition: .3s ease 0s;
}

.chiffreCrea2 {
    font-size: 22pt;
    line-height: 18pt;
}

/* effect */
.chiffreCrea:hover , .chiffreCrea2:hover{
    position: relative;
    color:#005b94;
}

.chiffreCrea:hover {
    font-size: 54pt;
}
.chiffreCrea2:hover {
    font-size: 27pt;
}


#imageTemoin {
    border-radius: 50% !important;
    width: 90px;
    padding: 10px;
    position: relative;
    left: 16px;

}

.card-text {
    line-height: 16px;
    color: #605f5f;
    font-size: 10pt;
    font-style: italic;
    text-align: justify;
}

.nameTem,
.funcTem {
    font-weight: bolder;
    position: relative;
    font-size: 12pt;
    top: 6px;

}

.card {
    background-color: #f3f1e4 !important;
    border: none !important;
}

.socialicon {
    font-size: 27pt !important;
    cursor: pointer;
    transition: transform .3s ease 0s;
}

.socialicon:hover {
    transform: scale(125%);
    rotate: -10deg;
}
.socialsvg {
    width: 36px;
    cursor: pointer;
    transition: transform .3s ease 0s;;
}
.socialsvg:hover {
        transform: scale(125%);
        rotate: -10deg;
 }


.socialiconfooter {
    font-size: 15pt !important;
    cursor: pointer;
    transition: transform .3s ease 0s;
}

.socialiconfooter:hover {
    transform: scale(125%);
    rotate: -10deg;
}

.socialiconfootersvg {
    width: 22px !important;
    cursor: pointer;
    transition: transform .3s ease 0s;
}

.socialiconfootersvg:hover {
    transform: scale(125%);
    rotate: -10deg;
}

.sociallogocontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.menu_items .links a {
    color: white;
    font-weight: bolder;
}

.menu_items .links a:hover {
    color: #fedd0d;
    text-decoration: none;
    font-weight: bolder;
}

.mobilemenu_items .linksmobilemenu a {
    color: #005b94;
    font-weight: bolder;
}

.mobilemenu_items .linksmobilemenu a:hover {
    color: #fddb00;
    font-weight: bolder;
    text-decoration: none;
}

.linkfootr a{
    color:white;
}
.linkfootr a:hover{
    color:#fddb00;
    text-decoration: none;
}
.navbar_container {
    background-color: #005b94;
    width:100vw;
    margin: auto;
    transition: .18s;
}

.mycustomnav_container {
    width: 1190px;
    margin: auto;
}

/* fixed navbar on little scroll */
.stickNavbar {
    position: fixed !important;
    background-color: white;
    z-index: 2;

}
.stickMenuitems {
    padding-top:37px;
}
.stickMenuitems a {
    color: #005b94 !important;
}
.stickMenuitems a:hover {
    color: #fddb00 !important;
}


/* responsive menu */


@media (max-width:1646px) { 

    .inscriptCol1 {
     padding-top: 70px;
 }
    
}
@media (max-width:1530px) {
       .inscriptCol1 {
        padding-top: 60px;
    }
}
/* responsive menu */
@media (max-width:992px) {

/* fixed little scroll state */
.stickNavbadandlogo {
    padding-right: 10px;
    padding-bottom: 10px;
    width:100vw;
}

.mynavbarandlogo{
    padding-right: 10px;
    padding-bottom: 10px;
    width:100vw;
}
    .menu_items {
        display: none;
    }

    

    .togglebtn_items {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        gap: 25px;
    }

    .mobilemenu_items {
        display: flex;
        flex-direction: column;
        width: 100vw;
    }
}

@media (max-width:915px) {
    .topblue_container {
        height:670px;
    }
    .inscriptCol1 {
        padding-top: 40px;
    }
}
@media (max-width:480px) {
    .topblue_container {
        height:640px;
    }
    .inscriptCol1 {
        padding-top: 40px;
    }
}

</style>
