import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { BootstrapVue, BootstrapVueIcons, NavbarPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(NavbarPlugin)

//install Axios
Vue.use(VueAxios, axios)

Vue.config.productionTip = false

export const globalSiteSetting = new Vue({
  data: {
    
    //siteurl: 'http://10.20.30.90:8888/creasavant_api'
     //siteurl: 'http://192.168.58.170:8888/creasavant_api'
     siteurl: 'https://www.creasavant.com'
   

  }
})

new Vue({
  render: h => h(App),
}).$mount('#app')
